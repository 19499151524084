.Card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.photo-card-image-wrapper {
  margin-top: 16px;
  width: 67%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0 4px 26px rgba(0, 0, 0, 0.24);
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.24);
}

.auth-code-text {
  width: 67%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.photo-card-image-wrapper > img {
  width: 100%;
  aspect-ratio: 4/3;
}

.auth-code-number-title {
  margin-top: 20px;
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: 20px;
}

.auth-code-number {
  /* margin-top: 10px; */
  width: 100%;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 36px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  /* border: 1px solid black; */
  box-shadow: inset 0 -16px 0 #9dc0f4;
}
.auth-code-number > span {
  position: relative;
  top: 2px;
}

.auth-code-desc1 {
  margin-top: 20px;
  font-family: "Noto Sans KR";
  font-size: 14px;
  color: #767676;
}

.auth-code-desc2 {
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: 16px;
  color: #1c3762;
}

.auth-code-expires-in {
  margin-top: 30px;
  font-family: "Noto Sans KR";
  font-size: 12px;
  color: #767676;
}

.confirm-button {
  width: 100%;
  height: 56px;
  font-family: "Noto Sans KR";
  font-weight: bold;
  font-size: 16px;
  background-color: #1c3762;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
